import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';

import { mainTheme } from '@/app/styles/themes/theme';

const container = document.getElementById('root');
if (!container) {
  throw new Error('Контейнер на найден');
}
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={mainTheme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
);
