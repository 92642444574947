// assets
// import InterRegularTTF from '@/assets/fonts/Inter-Regular.ttf'
import { createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';

const scrollStyle = {
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: 'rgba(85, 85, 85, 0.31)',
  }
};


export const baseShadow = '0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014';

export const mainTheme = createTheme({
  typography: { fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '6px',
          // height: '42px', //todo
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '1rem',
          fontWeight: 400,
          '&:hover > .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)' }
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiFormLabel-root': { color: theme.palette.grey[ '600' ] },
          '& :hover > .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main }
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: '6px',
          textTransform: 'none',
          boxShadow: 'none',
          height: '37px',
          fontSize: '0.8rem',
          fontWeight: 500,
          lineHeight: '15px',
          color: ownerState.variant === 'contained' ? theme.palette.getContrastText(theme.palette.text.primary) : theme.palette.primary.main,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ':hover': { boxShadow: 'none', }
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '&:not(.MuiDialog-paperFullScreen)': {
            borderRadius: '10px',
            maxWidth: '95%',
            maxHeight: '70%',
          }
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: () => ({
          borderRadius: '6px',
          alignItems: 'center',
          justifyContent: 'center',
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: scrollStyle,
        root: ({ theme }) => ({
          '.MuiFormLabel-root': { color: theme.palette.grey[ '600' ] },
          '& :hover > .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main }
        })
      }
    },
    MuiDrawer: { styleOverrides: { paper: scrollStyle, } },
    MuiTableContainer: { styleOverrides: { root: scrollStyle } },
    MuiStack: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      styleOverrides: { root: scrollStyle, }
    }
  },
  palette: {
    background: { default: '#f3f3f3', },
    primary: { main: '#57A5F1', },
    secondary: {
      main: '#d84315',
      light: '#ff7543',
      dark: '#9f0000',
    },
    text: {
      primary: '#343a40',
      secondary: '#858a8d',
    },
    table: {
      tableBg: '#F7FAFC',
      tableBorder: '#E2E8F0',
    }
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    table: Record<string, CSSProperties['color']>
  }

  interface PaletteOptions {
    table: Record<string, CSSProperties['color']>
  }

  interface Shadow {
    base: string;
  }
}
